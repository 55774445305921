import React from 'react'

// Component
import Text from 'components/elements/Text'
import Element from 'components/elements/Element'

// Third Party
import styled from 'styled-components'

interface TextsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const EmptyColumn = (
  props: GatsbyTypes.WpPage_Flexcontent_Flex_Text_blocks
) => (
  <div
    className={`col-xl-${props.widths?.desktopwidth} col-lg-${props.widths?.laptopwidth} col-md-${props.widths?.tabletwidth} col-${props.widths?.mobilewidth}`}
  />
)

const TextsLiteral: {
  [key: string]: any
} = {
  text: Text,
  element: Element,
  empty: EmptyColumn,
}

const Texts: React.FC<TextsProps> = ({ fields }) => (
  <section>
    <div className="container">
      <div className={`row justify-content-${fields.alignment}`}>
        {fields.blocks?.map((block, index) => {
          const type = block?.type || 'text'
          const Component = TextsLiteral[type]
          return (
            <Component
              key={index}
              {...block}
              parent={fields}
              type={fields.type}
            />
          )
        })}
      </div>
    </div>
  </section>
)

export default Texts
