import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled, { css } from 'styled-components'

const StyledText = styled.section<{ type?: string }>`
  line-height: 30px;

  & p {
    & b,
    strong {
      font-size: 20px;
    }
  }

  ${(props) =>
    props.type === 'compact' &&
    css`
      h1 {
        font-size: ${({ theme }) => theme.font.size.large};
        font-weight: ${({ theme }) => theme.font.weight.bold};
      }

      h2 {
        font-size: ${({ theme }) => theme.font.size.medium};
        font-weight: ${({ theme }) => theme.font.weight.bold};
      }

      & p,
      ul,
      ol {
        font-size: ${({ theme }) => theme.font.size.small};
        font-weight: ${({ theme }) => theme.font.weight.light};
        line-height: ${({ theme }) => theme.font.size.large};
      }
    `}
`

const UspHeading = styled.div`
  color: ${({ theme }) => theme.color.primary};
  font-weight: ${({ theme }) => theme.font.weight.light} !important;
  font-size: 35px;
  line-height: 40px;

  @media (max-width: 767px) {
    font-size: 24px;
  }
`

const Dot = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
  height: 6px;
  width: 6px;
  border-radius: 100%;
  margin: 0 10px;

  @media (max-width: 767px) {
    margin: 0 10px;
  }
`

const Text = (props: any) => (
  <StyledText
    type={props.type}
    className={`my-lg-0 my-3 col-xl-${props.widths?.desktopwidth} col-lg-${props.widths?.laptopwidth} col-md-${props.widths?.tabletwidth} col-${props.widths?.mobilewidth}`}
  >
    {props.parent.uspheading && (
      <UspHeading className="pb-3 d-flex align-items-center">
        <div>snel</div>
        <Dot />
        <div>simpel</div>
        <Dot />
        <div>specialistisch</div>
      </UspHeading>
    )}
    <ParseContent content={props?.description || ''} />
  </StyledText>
)

export default Text
