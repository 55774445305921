import React from 'react'

// Components
import FlexContentHandler from 'components/shared/FlexContentHandler'

// Third Party
import styled from 'styled-components'

const StyledElement = styled.section``

const Element = (props: any) => (
  <StyledElement
    className={`my-lg-0 my-3 col-xl-${props.widths?.desktopwidth} col-lg-${props.widths?.laptopwidth} col-md-${props.widths?.tabletwidth} col-${props.widths?.mobilewidth}`}
  >
    <FlexContentHandler
      prefix="Fragment_FlexFragment"
      fields={props.element.flex_fragment}
      location={props.location}
    />
  </StyledElement>
)

export default Element
